.heroContainer {
  width: 100%;
  max-width: 100vw;
  height: 80vh;
  position: relative;
  z-index: 0;
}

.heroContent {
  position: absolute;
  width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-white);
  z-index: 5;
}

.heroOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: var(--color-overlay);
}

.heroContainer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sectionContainer {
  padding: 2rem 0;
  z-index: 10;
  position: relative;
}

.info {
  margin-top: -5rem;
  z-index: 10;
}

.serviceContent {
  padding: 1.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.textLeft {
  flex-direction: row-reverse;
}

.seriviceImageWrapper {
  width: 300px;
  height: 300px;
  max-width: 50%;
  border-radius: 50%;
  margin: 0 2rem;
  overflow: hidden;
}

.seriviceImageWrapper img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.seriviceTextWrapper {
  max-width: 50%;
  margin-bottom: 1rem;
}

.seriviceTextWrapper h3{
  color: var(--color-primary);
}

.seriviceTextWrapper p{
  margin-bottom: 2rem;
}

.tableSection {
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.tableSection {
  margin-bottom: 1rem;
}

.tableSection span {
  margin: .5rem 0
}

.ctaButton {
  padding: .5rem 1rem;
  border-radius: 2rem;
  border: none;
  padding: 1rem;
  text-decoration: none;
}

.ctaWhatsApp {
  background-color: #23D366;
  color: #fff;
}

.ctaWhatsApp:hover {
  color: #fff;
  background-color: hsl(143, 72%, 35%);
  transition: var(--transition);
}

.ctaButton svg{
  margin-left: .5rem;
  font-size: 2rem;
}

@media(max-width: 900px) {

  .heroContent {
    width: 90%;
  }

  .serviceContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 .5rem;
    margin-bottom: 2rem;
  }

  .seriviceImageWrapper {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 2rem 1rem 0;
    overflow: hidden;
  }

  .seriviceTextWrapper {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .ctaButton {
    font-size: 14px;
  }
}